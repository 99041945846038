<script>
import { ValidationObserver } from 'vee-validate'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms/use-cms'
import { useHelp } from '/~/composables/help'
import { useProvider } from '/~/composables/provider'
import { useUser, useUserEvents } from '/~/composables/user'

export default {
  name: 'drawer-help',
  components: {
    BaseInput,
    BaseButton,
    BaseIcon,
    BaseAsidePage,
    BaseTextarea,
    ValidationObserver,
  },
  setup() {
    const { user } = useUser()
    const { sendHelp } = useHelp()
    const {
      helpTitle,
      helpDescription,
      helpMessageMaxLength,
      helpButtonTitle,
      helpFooterNote,
    } = useCms()
    const { customerServiceNumber } = useProvider()
    const { helpVisitedEvent } = useUserEvents()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()

    helpVisitedEvent({ type: 'drawer' })

    return {
      user,
      sendHelp,
      helpTitle,
      helpDescription,
      helpMessageMaxLength,
      helpButtonTitle,
      helpFooterNote,
      customerServiceNumber,
      backendErrors,
      validationObserverRef,
      processBackendErrors,
    }
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
      submitting: false,
    }
  },
  computed: {
    contactPhone() {
      // Value might be there, but be empty string
      return this.customerServiceNumber || '1300 059 257'
    },
  },
  created() {
    this.form.name = this.user.fullName || ''
    this.form.email = this.user.email || ''
  },
  methods: {
    async onSubmitForm() {
      this.submitting = true

      this.sendHelp(this.form)
        .then(() => {
          this.$emit('confirmation')
          this.form.message = ''
        })
        .catch(({ response }) => {
          this.processBackendErrors(response.data || response)
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<template>
  <base-aside-page title="Help">
    <div class="mb-2.5 font-bold text-eonx-neutral-600">
      {{ helpTitle ?? 'Send us a message' }}
    </div>
    <div v-if="helpDescription" class="mb-[30px]" v-html="helpDescription" />
    <div class="mb-[30px] rounded-lg bg-light p-5">
      <validation-observer
        v-slot="{ handleSubmit }"
        ref="validationObserverRef"
        slim
      >
        <form @submit.prevent="handleSubmit(onSubmitForm)">
          <base-input
            v-model="form.name"
            :validation="{
              rules: 'required',
              name: 'First Name',
            }"
            :disabled="submitting"
            :error="backendErrors.name"
            label="Name"
            required
            name="name"
          />
          <base-input
            v-model="form.email"
            :validation="{
              rules: 'required',
              name: 'Email',
            }"
            :disabled="submitting"
            :error="backendErrors.email"
            label="Email"
            required
            name="email"
            type="email"
          />
          <base-textarea
            v-model="form.message"
            :validation="{
              rules: 'required',
              name: 'Message',
            }"
            :disabled="submitting"
            :error="backendErrors.message"
            :maxlength="helpMessageMaxLength"
            :rows="4"
            :label="
              helpMessageMaxLength
                ? `Message (max character length ${helpMessageMaxLength})`
                : 'Message'
            "
            required
            name="message"
          />
          <base-button
            type="submit"
            :disabled="submitting"
            full-width
            class="mt-10"
          >
            {{ submitting ? 'Sending...' : helpButtonTitle ?? 'Send message' }}
          </base-button>
        </form>
      </validation-observer>
    </div>
    <div class="mb-2.5 font-bold text-eonx-neutral-600">Call us</div>
    <div class="mb-[30px] rounded-lg bg-light p-5">
      <a
        class="flex items-center justify-center text-xl font-bold no-underline"
        :href="`tel:${contactPhone}`"
      >
        <base-icon
          class="mr-2.5 mt-[5px] text-primary"
          size="md"
          svg="menu/call"
        />
        {{ contactPhone }}
      </a>
      <div class="mt-[15px] text-center">
        Please note, calls and emails are managed 9.00am to 5.00pm (AEST),
        Monday to Friday.
      </div>
    </div>
  </base-aside-page>
</template>
